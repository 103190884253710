import ModularContent, { ModularContentObject } from 'components/ModularContent'

import ContentContainer from 'components/ContentContainer'
import { H1 } from '@mehilainen/design-system'
import React from 'react'
import Seo from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export default ({ data }: Props) => {
  const pageData = data.magento.CmsPage
  const modular_content = pageData.content_constructor_content
    ? JSON.parse(pageData.content_constructor_content)
    : undefined
  return (
    <>
      <Seo
        title={pageData.title}
        metatitle={pageData.meta_title}
        description={pageData.meta_description}
        image={data.socialShareImage.nodes[0].publicURL}
      />
      <ContentContainer>
        <Content>
          <Title>{pageData.title}</Title>
          {modular_content
            ? modular_content.map(
                (content: ModularContentObject, index: number) => (
                  <ModularContent content={content} key={index} />
                )
              )
            : undefined}
        </Content>
      </ContentContainer>
    </>
  )
}

const Title = styled(H1)`
  text-align: center;
`
const Content = styled.div`
  margin: 30px 0;
`

interface Props {
  data: {
    magento: {
      CmsPage: CmsPage
    }
    socialShareImage: {
      nodes: { publicURL: string }[]
    }
  }
  pageContext: {
    lang: string
  }
}

export interface CmsPage {
  identifier: string
  content: string
  content_constructor_content: string
  meta_description: string
  meta_keywords: string
  meta_title: string
  title: string
  url_key: string
}

export const query = graphql`
  query CmsPage($identifier: String) {
    magento {
      CmsPage: cmsPage(identifier: $identifier) {
        identifier
        content
        content_constructor_content
        meta_description
        meta_keywords
        meta_title
        title
        url_key
      }
    }
    socialShareImage: allFile(filter: { name: { eq: "socialshareimage" } }) {
      nodes {
        publicURL
      }
    }
  }
`
